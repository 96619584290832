.transition {
  transition: .0s ease-in;
}

.banner {
  position: relative;
  box-sizing: border-box;
  height: calc(100vh - 117px);
  margin-bottom: 30px;
  padding-top: 170px;
  background-image: url(../../img/banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.banner__title {
  font-size: 70px;
  color: #fff;
  line-height: 1;
  text-transform: uppercase;
}

.banner__title strong {
  font-weight: 700;
  color: #1d433d;
}

.banner__title span {
  display: inline-block;
  margin-top: 30px;
  font-size: 40px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 2px;
}

.banner__btnWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 60px;
}

.banner__btn {
  box-sizing: border-box;
  max-width: 235px;
  width: 100%;
  line-height: 63px;
  margin-right: 25px;
  font-size: 25px;
  letter-spacing: 1.25px;
  text-transform: none;
  border-radiust: 45px;
}

.banner__btnText {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
}

.banner__scrollWrap {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.banner__scrollPic {
  display: block;
  margin: 0 auto 10px;
  animation: toScroll 1.7s ease-out infinite forwards;
}

.banner__scrollText {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 1.1px;
  color: #fff;
}

@keyframes toScroll {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1199px) {
  .banner {
    padding-top: 75px;
    height: 800px;
  }
}

@media screen and (max-height: 800px) {
  .banner {
    height: 800px;
  }
}

@media (max-width: 991px) {
  .banner {
    background-image: url(../../img/banner-tablet.png);
    height: 560px;
    padding-top: 110px;
  }
  .banner__title {
    font-size: 50px;
  }
  .banner__title span {
    margin-top: 10px;
    font-size: 30px;
  }
  .banner__scrollWrap {
    display: none;
  }
  .banner__btnWrap {
    display: inline-block;
    text-align: center;
  }
  .banner__btn {
    margin-right: 0;
    margin-bottom: 10px;
    width: 183px;
    font-size: 22px;
    line-height: 49px;
  }
  .banner__btnText {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .banner {
    background-image: url(../../img/banner-tablet.png);
    height: 350px;
    padding-top: 35px;
  }
  .banner__title {
    font-size: 28px;
  }
  .banner__title span {
    width: 140px;
    margin-top: 10px;
    font-size: 18px;
  }
  .banner__btnWrap {
    margin-top: 40px;
  }
  .banner__btn {
    margin-right: 0;
    margin-bottom: 5px;
    width: 128px;
    font-size: 15px;
    line-height: 33px;
  }
  .banner__btnText {
    font-size: 12px;
  }
}

.textBlock__text {
  max-width: 870px;
  margin: 0 auto;
}

.textBlock .line {
  width: 100%;
  max-width: 188px;
  height: 4px;
  background-color: #9eb670;
  margin: 30px auto;
}

@media (max-width: 991px) {
  .textBlock__text {
    max-width: 670px;
  }
  .textBlock .line {
    margin: 20px auto;
  }
}

@media (max-width: 767px) {
  .textBlock__text {
    max-width: calc(100% - 30px);
  }
  .textBlock .line {
    max-width: 77px;
    margin: 15px auto;
  }
}

.howItWork__title {
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: #1d433d;
  text-align: center;
}

.howItWork__items {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
}

.howItWork__item {
  padding-top: 70px;
}

.howItWork__btn {
  width: 320px;
  background: #9eb670;
  font-size: 25px;
  letter-spacing: 1.25px;
  display: block;
  margin: 0 auto;
  color: #fff;
  text-transform: none;
  line-height: 63px;
}

.howItWork__btn:hover {
  background: #64855b;
  border-color: #64855b;
  color: #fff;
}

.howItWork__btn:focus {
  background: #9eb670;
  border-color: #9eb670;
  color: #fff;
}

.howItWork__btn:active {
  background: #64855b;
  border-color: #64855b;
  color: #fff;
}

.howItWork__text {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.9px;
}

.howItWork .blockItem {
  width: 370px;
  min-height: 460px;
}

@media (max-width: 1200px) {
  .howItWork .blockItem {
    width: 310px;
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  .howItWork__title {
    font-size: 30px;
  }
  .howItWork__items {
    margin-bottom: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .howItWork__text {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 18px;
  }
  .howItWork__btn {
    width: 250px;
    font-size: 22px;
    line-height: 49px;
  }
  .howItWork__text {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .howItWork__items {
    margin-top: 0;
  }
  .howItWork__btn {
    width: 192px;
    font-size: 18px;
    line-height: 37px;
  }
  .howItWork__text {
    font-size: 13px;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .howItWork .blockItem {
    margin: 0 auto;
    width: 270px;
    min-height: 300px;
  }
}

.blockItem {
  background-color: #f1f3f8;
  padding: 80px 20px 0 20px;
  box-sizing: border-box;
  position: relative;
}

.blockItem__icon {
  position: absolute;
  width: 130px;
  height: 130px;
  display: block;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.blockItem__title {
  margin: 10px 0 20px 0;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
  color: #9eb670;
  text-align: center;
  text-transform: uppercase;
}

.blockItem__text {
  color: #000;
  font-family: "OpenSans", sans-serif;
}

@media (max-width: 991px) {
  .blockItem__title {
    font-size: 24px;
  }
  .blockItem__text {
    font-size: 15px;
    text-align: left;
  }
}

@media (max-width: 767px) {
  .blockItem {
    padding: 50px 10px 15px 10px;
  }
  .blockItem__icon {
    height: 100px;
  }
  .blockItem__title {
    font-size: 15px;
    line-height: 18px;
  }
  .blockItem__text {
    font-size: 12px;
  }
}

.video {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.video__descWrap {
  position: relative;
  flex-basis: 40%;
  background-color: #64855b;
  padding-top: 135px;
  padding-bottom: 180px;
  display: flex;
  justify-content: flex-end;
}

.video__descWrap:before {
  content: url("../../img/svg/gold-monet.svg");
  display: inline-block;
  position: absolute;
  width: 228px;
  height: 326px;
  top: 0;
  left: 0;
  transform: translateY(-50%);
}

.video__desc {
  max-width: 300px;
  margin-right: 115px;
  color: #fff;
}

.video__title {
  font-size: 60px;
  line-height: 65px;
  color: #fff;
  text-transform: uppercase;
}

.video__line {
  display: inline-block;
  width: 170px;
  height: 4px;
  background-color: #9eb670;
  margin: 40px 0;
}

.video__prev {
  flex-grow: 1;
  background-color: #9eb670;
  position: relative;
}

.video__btn {
  position: absolute;
  width: 142px;
  height: 142px;
  border-radius: 50%;
  background-color: #fff;
  background-image: url(../../img/svg/triangle-play.svg);
  background-position: center;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  cursor: pointer;
  transition: .2s ease;
}

.video__btn:hover {
  background-color: #9eb670;
  background-image: url(../../img/svg/triangle-play-white.svg);
}

.video__btn_load {
  animation: pulse 1s infinite;
  background-color: #9eb670;
  background-image: url(../../img/svg/triangle-play-white.svg);
}

.video__prevPic {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(../../img/video-bg.jpg);
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
  z-index: 1;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 5px 0 rgba(158, 182, 112, 0.4);
  }
  70% {
    box-shadow: 0 0 5px 25px rgba(158, 182, 112, 0);
  }
  100% {
    box-shadow: 0 0 5px 0 rgba(158, 182, 112, 0);
  }
}

@media (max-width: 1200px) {
  .video__descWrap {
    padding-top: 70px;
    padding-bottom: 80px;
  }
  .video__descWrap:before {
    display: none;
  }
  .video__desc {
    margin-right: 40px;
  }
  .video__line {
    margin: 20px 0;
  }
}

@media (max-width: 991px) {
  .video__descWrap {
    flex-basis: 29%;
  }
  .video__title {
    font-size: 30px;
    line-height: 1.2;
  }
  .video__desc {
    max-width: 180px;
  }
  .video__btn {
    width: 59px;
    height: 59px;
    background-size: 10px;
  }
}

@media (max-width: 767px) {
  .video {
    flex-wrap: wrap;
    margin-bottom: 110px;
    position: relative;
  }
  .video__descWrap {
    flex-basis: 100%;
    padding-top: 25px;
  }
  .video__desc {
    max-width: 210px;
    margin: 0 auto;
  }
  .video__title {
    font-size: 26px;
    text-align: center;
  }
  .video__line {
    width: 70px;
    margin: 15px auto;
    display: block;
  }
  .video__text {
    font-size: 14px;
    font-weight: 300;
  }
  .video__prev {
    position: absolute;
    width: 100%;
    max-width: 290px;
    height: 180px;
    top: calc(100% - 65px);
    left: 50%;
    transform: translateX(-50%);
  }
  .video__btn {
    width: 49px;
    height: 49px;
    background-size: 10px;
  }
}
